<template>
  <div class="account_list">
    <van-nav-bar
      title="账号列表"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <van-notice-bar
        wrapable
        :scrollable="false"
        text="注销不使用的账号后，在对应小程序里退出重新登陆。"
      />
      <van-cell v-for="(item, index) in list" :key="index">
        <template #title>
          <van-image
            :src="item.avatar"
            width="40"
            height="40"
            round
            lazy-load
            fill="cover"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </template>
        <template #default>
          <div class="van-ellipsis" style="font-size: 12px;">{{item.nickname}}</div>
          <div>
            <van-tag type="primary">{{item.levelname}}</van-tag>
            <van-button @click="copy(item.agent_code)" size="mini" color="linear-gradient(to right, #ff6034, #ee0a24)" style="height: 18px;margin-right: 10px;">{{item.agent_code}}</van-button>
          </div>
          <div>
            <van-tag plain type="danger">邀请人数量：{{item.next_member_num}}</van-tag>
            <van-tag plain type="danger">未完成订单：{{item.order_num}}</van-tag>
          </div>
        </template>
        <template #right-icon>
          <van-button @click="delAccount(index)" size="mini" :disabled="item.id == memberid">注销</van-button>
        </template>
      </van-cell>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant';
export default {
  name: "AccountTwoList",
  data() {
    return {
      openid: '',
      reload: 0,
      info: {},
      iswx: window.isWeixin(),
      merchid: 0,
      shareid: 0,
      list: [],
      memberid: 0,
    }
  },
  mounted() {
    this.iswx = window.isWeixin();
    if (!window.isWeixin()) {
      this.$toast("请在微信中打开");
      return false;
    }
    this.reload = localStorage.getItem('testReload') ? localStorage.getItem('testReload') : 0;
    console.log(this.iswx, this.reload, "ssssss");
    if (this.iswx) { // 在微信中
      // 用户授权后获取code值
      this.code = this.$route.query.code ? this.$route.query.code : null;
      if (this.code) {
        this.reload = 1;
      }else {
        this.reload = 0;
      }
      if (this.reload == 1 && this.code) {
        this.getwxlog(this.code);
      }else {
        console.log("this.getwxinfo()");
        this.getwxinfo(); // 自动获取并保存新用户信息
      }
    }
  },
  methods: {
    /* 判断是否在微信中打开，如在微信中打开获取openid或用户信息 */
    getwxinfo() {
      let _that = null;
      _that = this;
      /* 以下下是获取openID 以及判断用户是否在微信中打开页面 */
      if (!window.isWeixin()) {
        this.reload = 1;
        this.iswx = false; 
      }
      
      console.log(this.reload, "this.reload", this.iswx);
      /* 在微信中打开页面 */
      if (this.reload != 1 && this.iswx) { 
        this.iswx = true; 
        this.reload = 1; 
        localStorage.setItem("testReload", 1);
        const wxAuthUrl2 = 
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc465682b705715e5&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
          // 传到后台得到openid, 其中access_token 2小时过期
          if (_that.code == null || _that.code === "") {
            window.location.href = wxAuthUrl2; // [自刷新当前页]
          }
      } else {
        // localStorage存在openid || 本页面不在公众号中打开
        console.log("localStorage存在openid || 本页面不在公众号中打开");
      }
    },
    // 获取用户微信信息
    getwxlog(code) {
      let _that = null;
      _that = this;
      const toast = this.$toast({
        message: '加载中...',
        type: 'loading',
        duration: 0
      })
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            _that.$axios
              .post(
                _that.$store.state.domain + "web/wetch/get_wxsnsinfo",
                _that.$qs.stringify({
                  openid: response.data
                })
              )
              .then(res => {
                toast.clear();
                localStorage.setItem("testReload", 2);
                if (res.data && res.data.headimgurl) {
                  if (res.data.openid == response.data) {
                    _that.reloapage(res.data, response.data)
                  }
                  _that.info = res.data;
                }
              })
              .catch(error => {
                console.log(error);
              })
          }
        })
        .catch(error => {
          console.log(error);
        })
    },

    /* 重新刷新页面 */
    reloapage(info,openid) {
      let _that = null;
      _that = this;
      _that.$router.replace({ name: "AccountTwoList", query: {shareid: _that.shareid, merchid: _that.merchid, _t: 3 } });// 成功后重定向，需要去除code参数，防止刷新报错(localStorage['DK_OPENID']控制是否刷新code)
      _that.getlist(info,openid);
    },

    getlist(info,openid) {
      let that = null;
      that = this;
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      that.$axios
        .post(
          that.$store.state.domain + 'web/user/get_account_list',
          that.$qs.stringify({
            openid: openid,
            wxinfo: info
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.list = res.data.data;
          }else {
            that.$toast(res.msg ?res.msg : '获取失败');
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 删除账号
    delAccount(index) {
      let that = null;
      that = this;
      let info = {};
      info = this.list[index];
      let msg = '';
      msg = "确认要注销邀请码为："+info.agent_code+" 的账号？注销后关于此账号的所有信息都将无法找回,请慎重！！！"
      Dialog.confirm({
        title: '注销账号',
        message: msg,
      })
        .then(() => {
          that.dodel(index);
        })
        .catch(() => {
          // on cancel
        });
    },
    dodel(index) {
      let that = null;
      that = this;
      let info = {};
      info = this.list[index];
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      that.$axios
        .post(
          that.$store.state.domain + 'web/user/set_account',
          that.$qs.stringify({
            openid: info.openid,
            info: info
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "注销成功");
            // that.list = res.data.data;
            that.list.splice(index, 1);
          }else {
            that.$toast(res.data.msg ? res.data.msg : "注销失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 复制邀请码
    copy(txt) {
      let _that = null;
      _that = this;
      _that.$copyText(txt).then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
    onClickLeft() {
      this.$router.back();
    },
  }
}
</script>
<style lang="less">
.account_list {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: #fff;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .main {
    .van-cell__title {
      margin-right: 10px;
    }
    .van-cell__value {
      flex: 1;
      text-align: left;
      color: #333;
      .van-tag {
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
</style>